<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>Madcouple0818</h1>
          <div class="description" v-if="langcode == 'en' ">
            Madcouple0818 is a premium men's fashion label that offers contemporary and stylish clothing, accessories, and footwear. Our brand is known for its attention to detail, quality craftsmanship and comfort. <br/>
            Our primary target audience is who interest in fashion and personal style. trend-conscious,appreciate quality!
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            Mad Couple0818 是一個優質男士時尚品牌，提供現代時尚的服裝、配件和鞋類。我們的品牌以其對細節的關注、優質工藝和舒適度而聞名。<br/>
            我們的主要目標受眾是對時尚和個人風格感興趣的人。引領潮流，品味品質！
          </div>
          <ul class="icons">
            <li>{{ $t('Address') }}: {{ $t('UNIT 12A 14/F WITTY COMMERCIAL BUILDING 1A/1L TUNG CHOI STREET MONG KOK KL')}}</li>
            <li>{{ $t('Tel') }}: 852-62341208</li>
          </ul>
          <a href="#" @click="viewAboutUs" style="margin-right: 10px">
            {{ $t('Company Overview')}}
          </a>
        </header>

        <!-- Footer -->
          <footer id="footer">
            <VueBotUI
            :messages="data"
            @msg-send="messageSendHandler"
            />
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy;  MADCOUPLE0818 LIMITED.</li>
            </ul>
          </footer>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Company Overview</h1>
          <h1 v-else>公司概要</h1>
          <div class="description" v-if="langcode == 'en' ">
            Mainly assists foreign orders to find suitable raw material matching in China or Taiwan and provides consulting services.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            DCDT丨BEAMS丨WTAPS丨 <br/>
            DAIWA PIER39丨SFC｜ <br/>
            SEESEE ｜TNFPL｜CAHLUMN <br/>
            旺角門市取貨(NOT FOR WALK-IN️) <br/>
            訂貨為主 <br/>
            約兩星期到貨 <br/>
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    //this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to Madcouple0818. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'products',
          'answer': "ANS:DCDT丨BEAMS丨WTAPS丨 DAIWA PIER39丨SFC｜SEESEE ｜TNFPL｜CAHLUMN  please review for more info: https://www.instagram.com/madcouple0818/",
        },
        {
          'question': 'place_order',
          'answer': 'ANS:旺角門市取貨(NOT FOR WALK-IN️) 訂貨為主(約兩星期到貨)',
        },
        {
          'question': 'contact',
          'answer': 'wa.me/message/74IRHCZR3SEDL1',
        },
        {
          'question': 'business_hours',
          'answer': 'MONDAY - OFF / TUESDAY - APPOINTMENT ONLY / WEDNESDAY - OFF / THURSDAY - OFF / FRIDAY - APPOINTMENT ONLY / SATURDAY - OFF /SUNDAY - OFF',
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewService(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.our-service');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });

      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if(answer){
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else{
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'These answer maybe able to help you:',
          disableInput: false,
          options: [
            {
              text: 'Products?',
              value: 'products',
              action: 'postback' // Request to API
            },
            {
              text: 'Order?',
              value: 'place_order',
              action: 'postback' // Request to API
            },
            {
              text: 'Contact?',
              value: 'contact',
              action: 'postback' // Request to API
            },
            {
              text: 'Business hours?',
              value: 'business_hours',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  padding-bottom: 30px;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-us, .our-service{
  display: none;
}
.partner{
  margin-top: 50px;
  .logos{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  img{
    display: block;
    width: 100%;
    max-width: 100px;
    margin-right: 10px;
  }
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
